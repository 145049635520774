.App {
  text-align: center;
}

.Map_container {
  width: 100vw;
  height: 80vh;
  display: block;
  margin: 20px auto;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.error{
  width: 300px;
  height: 300px;
  display: block;
  margin: 20px auto;
  background-color: #47ADD4;
  color: #fff;
  font-size: 30px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}